import React, { useCallback } from 'react';
import './App.css';
import { useMutastate } from 'mutastate';
import StateInput from './components/StateInput';
import Card from './components/Card';
import LabelCheckbox from './components/LabelCheckbox';
import { globalState } from './data/globalState';
import Button from './components/Button';
import StateSelect from './components/StateSelect';
import TextInputModal from './components/TextInputModal';
import { cloneDeep, keys } from 'lodash';

function safelyJoinUrlParts(...parts) {
  return parts.map((part) => part.replace(/(^\/|\/$)/g, '')).join('/');
}

function makeApiUrl(baseUrl, url, useSsl) {
  return `${useSsl ? 'https' : 'http'}://${safelyJoinUrlParts(baseUrl, url)}`;
}

function makeWsUrl(baseUrl, url, useSsl) {
  return `${useSsl ? 'wss' : 'ws'}://${safelyJoinUrlParts(baseUrl, url)}`;
}

export default function App(props) {
  const [profile] = useMutastate(['global', 'selected_profile'], { defaultValue: 'default' });
  const [profiles] = useMutastate(['profiles']);
  const [baseUrl] = useMutastate(['profiles', profile, 'sock', 'base_url']);
  const [url] = useMutastate(['profiles', profile, 'sock', 'url']);
  const [token, setToken] = useMutastate(['profiles', profile, 'sock', 'token']);
  const [email] = useMutastate(['profiles', profile, 'sock', 'email']);
  const [password] = useMutastate(['profiles', profile, 'sock', 'password']);
  const [useSsl] = useMutastate(['profiles', profile, 'sock', 'use_ssl']);
  const [messages, setMessages] = useMutastate(['logging', 'messages']);
  const [sockState, setSockState] = React.useState('disconnected');
  const [error, setError] = React.useState(null);
  const socket = React.useRef(null);
  const [modal, setModal] = React.useState(null);

  const addMessage = useCallback((message) => {
    const timestamp = new Date();
    const currentMessages = globalState.get(['logging', 'messages']) || [];
    const formattedMessage = `${timestamp.toISOString()} - ${message}`;
    console.log(formattedMessage);
    const updatedMessages = [formattedMessage].concat(currentMessages.slice(0, 9));
    globalState.set(['logging', 'messages'], updatedMessages);
  }, []);

  const connect = (url) => {
    setSockState('connecting', new Date());
    socket.current = new WebSocket(makeWsUrl(baseUrl, url, useSsl) + '?token=' + encodeURIComponent(token));
    socket.current.onopen = () => {
      console.log('Connected', new Date());
      setSockState('connected');
    };
    socket.current.onmessage = (message) => {
      addMessage(message.data);
    };
    socket.current.onclose = () => {
      console.log('Disconnected', new Date());
      setSockState('disconnected');
    };
    socket.current.onerror = (error) => {
      console.error(error, new Date());
      setError(error);
      setSockState('error');
    }
  }

  const login = (email, password) => {
    fetch(makeApiUrl(baseUrl, '/sd/v1/auth', useSsl), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email: email, password }),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      setToken(data.token);
    })
    .catch((error) => {
      console.error(error);
      setError(error);
    });
  };

  const showDuplicateProfileModal = useCallback(() => {
    setModal('duplicateProfile');
  }, [setModal]);

  const showNewProfileModal = useCallback(() => {
    setModal('newProfile');
  }, [setModal]);

  let modalView = null;

  if (modal) {
    switch (modal) {
      case 'duplicateProfile':
        modalView = <TextInputModal title="Duplicate Profile" onClose={() => setModal(null)} onSubmit={(name) => {
          globalState.set(['profiles', name], cloneDeep(globalState.get(['profiles', profile])));
          globalState.set(['global', 'selected_profile'], name);
          setModal(null);
        }} />;

        break;
      case 'newProfile':
        modalView = <TextInputModal title="New Profile" onClose={() => setModal(null)} onSubmit={(name) => {
          globalState.set(['profiles', name], {
            sock: {
              base_url: '',
              url: '',
              useSsl: true,
            },
          });
          globalState.set(['global', 'selected_profile'], name);
          setModal(null);
        }} />;

        break;
      default:
        modalView = null;
    }
  }

  return (
    <div className="App">
      {modalView}
      <header className="App-header">
        <h1>WebSocket Test</h1>
      </header>

      <Card title="Profile">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <StateSelect style={{ padding: 10 }} dataKey={['global', 'selected_profile']} list={keys(profiles)} label="Profile" type="select" />
        </div>
        <Button style={{ padding: 6 }} onClick={() => showDuplicateProfileModal()}>
          Duplicate Profile
        </Button>
        <Button style={{ padding: 6 }} onClick={() => showNewProfileModal()}>
          New Profile
        </Button>
        {profile !== 'default' && (
          <Button style={{ padding: 6 }} onClick={() => {
            if (window.confirm(`Are you sure you want to delete the ${profile} profile?`)) {
              const profilesWithoutCurrent = cloneDeep(profiles);
              delete profilesWithoutCurrent[profile];
              globalState.set(['profiles'], profilesWithoutCurrent);
              globalState.set(['global', 'selected_profile'], 'default');
            }
          }}>
            Delete Profile
          </Button>
        )}
      </Card>

      <Card title="WebSocket Configuration">
        <StateInput dataKey={['profiles', profile, 'sock', 'base_url']} label="Base URL" />
        <StateInput dataKey={['profiles', profile, 'sock', 'url']} label="Websock Sub URL" />

        <div style={{ marginTop: 24 }}>
          <LabelCheckbox dataKey={['profiles', profile, 'sock', 'use_ssl']} label="Use SSL" />
        </div>
      </Card>

      <Card title="Authentication">
        <div>
          <StateInput dataKey={['profiles', profile, 'sock', 'token']} label="Token" />
          <p>OR</p>
          <StateInput dataKey={['profiles', profile, 'sock', 'email']} label="Email" type="text" autoComplete="email" />
          <StateInput dataKey={['profiles', profile, 'sock', 'password']} label="Password" type="password" autoComplete="current-password" />
          {!token && <Button onClick={() => {login(email, password)}}>Login</Button>}
        </div>
      </Card>

      <Card title="WebSocket Control">
        {sockState === 'connected' && <Button onClick={() => socket.current.close()}>Disconnect</Button>}
        {sockState !== 'connected' && !!token && <Button onClick={() => connect(url)}>Connect</Button>}
      </Card>

      <Card title="WebSocket State">
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p>Socket State: {sockState}</p>
          <p>Error: {error && error.message}</p>
        </div>
      </Card>

      {messages && <Card title="Messages">
        <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '100%', flexShrink: '1' }}>
          {messages.map((message, index) => <p style={{ lineBreak: 'anywhere' }} key={index}>{message}</p>)}
        </div>
        <Button onClick={() => setMessages([])}>Clear Messages</Button>
      </Card>}

      <Card title="Send Message">
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <StateInput dataKey={['profiles', profile, 'sock', 'message']} label="Message" />
          <Button style={{ marginLeft: 24 }} onClick={() => {
            socket.current.send(globalState.get(['sock', 'message']));
            globalState.set(['sock', 'message'], '');
          }}>Send</Button>
        </div>
      </Card>
    </div>
  );
}
