import React, { useState } from 'react';
import Modal from './Modal';
import Button from './Button';

export default function TextInputModal(props) {
  const { title, onClose, onSubmit } = props;
  const [value, setValue] = useState('');

  return (
    <Modal title={title} onClose={onClose}>
      <input value={value} onChange={(e) => setValue(e.target.value)} />
      <Button onClick={() => {
        onSubmit(value);
        onClose();
      }}>Confirm</Button>
    </Modal>
  );
}
