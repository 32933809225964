import React from 'react';
import classes from './LabelCheckbox.module.css';
import { combineClasses } from '../utilities/combineClasses';
import { useMutastate } from 'mutastate';

export default function LabelCheckbox(props) {
  const { dataKey, label, className, labelClass, ...rest } = props;
  const [value = false, setValue] = useMutastate(dataKey);

  return (
    <label className={combineClasses(classes.label, labelClass)}>
      <input type="checkbox" className={combineClasses(classes.input, className)} checked={value} onChange={(e) => setValue(e.target.checked)} {...rest} />
      {label}
    </label>
  );
}
