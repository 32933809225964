import React from 'react';
import classes from './Button.module.css';
import { combineClasses } from '../utilities/combineClasses';

export default function Button(props) {
  const { className, children, ...rest } = props;

  return (
    <button className={combineClasses(classes.button, className)} {...rest} >
      {children}
    </button>
  );
}
