import React, { useEffect } from 'react';
import { initializeState } from '../data/globalState';

export default function StateLoader(props) {
  const [initialized, setInitialized] = React.useState(false);

  useEffect(() => {
    initializeState();
    setInitialized(true);
  }, [props]);

  return initialized ? props.children : null;
}
