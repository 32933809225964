import { useMutastate } from 'mutastate';
import React from 'react';
import classes from './StateInput.module.css';
import { combineClasses } from '../utilities/combineClasses';

export default function StateInput(props) {
  const { dataKey, label, className, labelClass, ...rest } = props;
  const [value, setValue] = useMutastate(dataKey);

  return (
    <label className={combineClasses(classes.label, labelClass)}>
      {label}
      <input className={combineClasses(classes.input, className)} placeholder={label} value={value || ''} onChange={(e) => setValue(e.target.value)} {...rest} />
    </label>
  );
}
