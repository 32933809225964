import { useMutastate } from 'mutastate';
import React, { useCallback } from 'react';

// mutastate connected component for option selection
export default function StateSelect(props) {
  const { dataKey, listKey, defaultValue, list, label, ...rest } = props;
  const [value, setValue] = useMutastate(dataKey);
  // const [list, setList] = useMutastate(listKey);

  const handleChange = useCallback((event) => {
    setValue(event.target.value);
  }, [setValue]);

  return (
    <select value={value} onChange={handleChange} {...rest}>
      {(list || []).map((item) => {
        const optionKey = item?.key || item;
        const optionLabel = item?.label || optionKey;
        return <option key={optionLabel} value={optionKey}>{optionLabel}</option>
      })}
    </select>
  );
}
