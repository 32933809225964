import React from 'react';
import classes from './Card.module.css';
import { combineClasses } from '../utilities/combineClasses';

export default function Card(props) {
  return (
    <div className={combineClasses(classes.card, props.className)}>
      <h2>{props.title}</h2>
      {props.children}
    </div>
  );
}
