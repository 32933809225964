import { omit, throttle } from 'lodash';
import { singleton } from 'mutastate';

export const globalState = singleton();

export async function initializeState() {
  const currentData = localStorage.getItem('sd-online-websock');

  if (currentData) {
    globalState.setEverything(JSON.parse(currentData));
  } else {
    globalState.setEverything({
      profiles: {
        'default': {
          sock: {
            base_url: 'api.getservicedesk.com',
            url: '/sd/v1/websock/businessData',
            useSsl: true,
          },
        },
        'local': {
          sock: {
            base_url: 'localhost:9595',
            url: '/sd/v1/websock/businessData',
            useSsl: false,
          },
        },
      },
      global: {
        selected_profile: 'default',
      },
    });
  }

  globalState.addChangeHook(throttle(({ key, value, meta }) => {
    const everythingWithOmission = omit(globalState.getEverything(), ['ephemeral', 'temp']);
    localStorage.setItem('sd-online-websock', JSON.stringify(everythingWithOmission));
  }, 500));
}
