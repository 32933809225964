import React, { Fragment } from 'react';
import classes from './Modal.module.css';

export default function Modal(props) {
  const { children, onClose, title } = props;

  return (
    <Fragment>
      <div className={classes['backdrop']} onClick={onClose}></div>
      <div className={classes.modal}>
        <div className={classes['modal-content']}>
          <div className={classes['modal-header']}>
            <h2>{title}</h2>
            <button className={classes.close} onClick={onClose}>X</button>
          </div>
          <div className={classes['modal-body']}>
            {children}
          </div>
        </div>
      </div>
    </Fragment>
  );
}
